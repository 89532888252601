import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useResizeObserver } from '@mantine/hooks';

const Button = dynamic(() => import('@components/elements/button'));

const Landing = () => {
  const router = useRouter();
  const [ref, rect] = useResizeObserver();

  return (
    <section className="landing-home" id="landing-home" ref={ref}>
      <div className="landing-home--container">
        <div className="landing-home--content">
          <div className="landing-home--content-info">
            <h1 className="landing-home--content-text">
              More Points More Rewards In One Single Apps
            </h1>
          </div>

          {rect.width <= 768 && (
            <div className="relative w-full h-[350px] flex items-center justify-center">
              <Image
                src="/images/illustrations/mobile.webp"
                alt="Chakrarewards"
                layout="fill"
                objectFit="contain"
              />
            </div>
          )}

          <div className="landing-home--content-action">
            <Button
              type="button"
              className="button button--primary"
              data-unq="landingpage-button-register"
              onClick={() => router.push('/register')}>
              Create Account
            </Button>
            <div className="landing-home--content-action-ps">
              <a
                href="https://play.google.com/store/apps/details?id=com.kalbe.cakraloyalty"
                target="_blank"
                data-unq="landingpage-button-playstore"
                rel="noreferrer">
                <Image
                  src="/images/vendors/playstore.webp"
                  alt="Google Playstore"
                  width={200}
                  height={60}
                  layout="fixed"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="landing-home--image">
          {rect.width >= 768 && <div className="landing-home--image-decoration"></div>}
          {rect.width >= 1024 && (
            <div className="landing-home--image-source">
              <Image
                src="/images/illustrations/desktop.webp"
                alt="Chakrarewards"
                width={600}
                height={570}
                layout="fixed"
                objectFit="contain"
                priority={true}
              />
            </div>
          )}
          {rect.width >= 768 && rect.width <= 1024 ? (
            <div className="landing-home--image-source">
              <Image
                src="/images/illustrations/mobile.webp"
                alt="Chakrarewards"
                width={350}
                height={500}
                layout="fixed"
                objectFit="contain"
              />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Landing;
